import React, { useState } from 'react';
import './index.css';
import logo from '../../assets/images/main-logo-gif.gif';

const InfoModal = ({ shown, onHide }) => {
  const [isShown, setIsShown] = useState(shown);

  const hide = () => {
    setIsShown(!isShown);
    onHide();
  };

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      hide();
    }
  };

  const modalContent = (
    <div className="info-modal-wrapper w-full relative flex flex-col items-center justify-center text-gray-600-color">
      <h3 className="text-2xl font-semibold">생카데이 4월 컴백🙂</h3>
      <img
        src={logo}
        alt=""
        className="2xs:w-20 2xs:h-20 xs:w-24 xs:h-24 2sm:w-28 2sm:h-28 sm:w-32 sm:h-32 tb:w-40 tb:h-40 lg:w-48 lg:h-48"
      />
      <ul className="info-modal-text">
        <li>
          초기 버전의 생카데이는 2024년 2월 생일인 세븐틴 디노, 도겸, 버논만
          운영합니다.
          <br />
          4월에 확장된 생카데이로 돌아오겠습니다!
        </li>
        <li>
          생카데이 소식은 SNS에서 확인해주세요 :) <br />
          <span>[메뉴 - 문의하기 - CONTACT MEI]</span>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="modal-wrapper" onClick={hide} onKeyDown={handleKeyDown}>
      <div className="modal-section">{modalContent}</div>
      <div className="modal-dimmed" onClick={hide} />
    </div>
  );
};

export default InfoModal;

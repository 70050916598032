import React from 'react';
import { Link } from 'react-router-dom';
import { cafes } from '../../libs/cafes';
import Header from '../../components/Header/Header';
import CafeCard from '../../components/CafeCard/CafeCard';

const infoEndDate = dateString => {
  const endDateString = dateString.split('~')[1].trim();
  const [year, month, day] = endDateString.split('.').map(Number);
  return new Date(year + 2000, month - 1, day);
};

const parseStartDate = dateString => {
  const startDateString = dateString.split('~')[0].trim();
  return startDateString.split('.').map(Number);
};

const Total = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const sortedCafes = cafes.slice().sort((a, b) => {
    const startDateA = parseStartDate(a.date);
    const startDateB = parseStartDate(b.date);
    const endDateA = infoEndDate(a.date);
    const endDateB = infoEndDate(b.date);

    // If endDateA is in the past, move it down in the sorting order
    if (endDateA < currentDate && endDateB < currentDate) {
      return (
        startDateA[2] - startDateB[2] ||
        startDateA[1] - startDateB[1] ||
        startDateA[0] - startDateB[0]
      );
    }

    // If endDateA is in the past, move it down in the sorting order
    if (endDateA < currentDate) {
      return 1;
    }

    // If endDateB is in the past, move it down in the sorting order
    if (endDateB < currentDate) {
      return -1;
    }

    // Sort by startDate if both endDateA and endDateB are in the future
    return (
      startDateA[2] - startDateB[2] ||
      startDateA[1] - startDateB[1] ||
      startDateA[0] - startDateB[0]
    );
  });

  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="전체 생일카페 리스트" />
      <div className="w-full flex flex-col gap-8 tb:gap-10 lg:gap-10 p-8 items-center justify-center">
        {sortedCafes.map(cafe => (
          <Link to={`/info/${cafe.id}`} key={cafe.date}>
            <CafeCard
              name={cafe.name}
              position={cafe.position}
              date={cafe.date}
              time={cafe.time}
              mainSrc={cafe.mainSrc}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Total;

import React from 'react';
import './index.css';
import tape from '../../assets/images/icon-tape.svg';

const infoEndDate = dateString => {
  const endDateString = dateString.split('~')[1].trim();
  const [year, month, day] = endDateString.split('.').map(Number);
  return new Date(year + 2000, month - 1, day);
};

const CafeCard = ({ name, position, date, time, mainSrc, link }) => {
  let endDate = infoEndDate(date);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const sectionBg = currentDate > endDate ? 'bg-neutral-400' : 'bg-white';
  const sectionOp = currentDate > endDate ? 'opacity-50' : 'opacity-1';

  return (
    <>
      <div className="absolute 2xs:mt-[-24px] 2xs:ml-28 xs:mt-[-30px] xs:ml-32 2sm:mt-[-36px] 2sm:ml-36 sm:mt-[-40px] sm:ml-48 tb:mt-[-48px] tb:ml-60 lg:mt-[-50px] lg:ml-60">
        <img
          src={tape}
          alt=""
          className={`${sectionOp} 2xs:w-12 xs:w-14 2sm:w-16 sm:w-20 tb:w-24 lg:w-24 h-auto rotate-[105deg]`}
        />
      </div>
      <a href={link} className="cursor-pointer">
        <section
          className={`${sectionBg} ${sectionOp} w-full 2xs:w-[248px] xs:w-[292px] 2sm:w-[328px] sm:w-[480px] tb:w-[560px] lg:w-[600px] lg:h-[280px] tb:h-[256px] sm:h-[240px] 2sm:h-[168px] xs:h-[152px] 2xs:h-[128px] flex flex-row gap-10 sm:gap-6 2sm:gap-4 xs:gap-4 2xs:gap-2 2xs:rounded-[10px] xs:rounded-[12px] rounded-2xl bg-white`}
        >
          <div>
            <img
              src={mainSrc}
              alt={name}
              className="lg:w-[210px] tb:w-[192px] sm:w-[180px] 2sm:w-[126px] xs:w-[114px] 2xs:w-[96px] h-full 2xs:rounded-s-[10px] xs:rounded-s-[12px] rounded-s-2xl"
            />
          </div>
          <div className="flex flex-col 2xs:pr-0 2xs:pt-4 xs:pr-0 xs:pt-5 2sm:pr-0 2sm:pt-6 sm:pr-10 pr-14 pt-10 pb-6 text-left items-start">
            <ul className="list-none text-left gap-2">
              <li className="lg:mb-16 tb:mb-14 sm:mb-8 2sm:mb-10 xs:mb-8 2xs:mb-6 2xs:text-[14px] xs:text-[16px] 2sm:text-[18px] sm:text-[22px] text-[24px] font-bold">
                {name}
              </li>
              <li className="2xs:text-[10px] xs:text-[10px] 2sm:text-[12px] sm:text-[14px] font-normal">
                {position}
              </li>
              <li className="2xs:text-[10px] xs:text-[10px] 2sm:text-[12px] sm:text-[14px] font-normal">
                {date}
              </li>
              <li className="2xs:text-[10px] xs:text-[10px] 2sm:text-[12px] sm:text-[14px] font-normal">
                {time}
              </li>
            </ul>
          </div>
        </section>
      </a>
    </>
  );
};

export default CafeCard;

import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import menu from '../../assets/images/icon-menu.png';

const Header = ({ title }) => {
  const renderTitle = () => {
    if (title) {
      return <span>{title}</span>;
    } else {
      return <a href="/">생카데이</a>;
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          id="dimmed"
          className="z-10 fixed top-0 left-0 w-full h-full bg-black opacity-70"
          onClick={closeSidebar}
        />
      )}

      <header className="w-full 2xs:h-12 h-16 lg:h-20 bg-MainBg bg-cover text-2xl 2xs:text-[16px] xs:text-[20px] 2sm:text-[22px] sm:text-[24px] tb:text-[28px] text-[30px] font-MainFont text-black flex flex-row justify-center items-center relative">
        <Sidebar isOpen={isOpen} closeSidebar={closeSidebar} />
        <div
          className="absolute 2xs:left-6 left-8 lg:left-10"
          onClick={toggleSidebar}
        >
          <img src={menu} alt="" className="2xs:w-5 xs:w-7 2sm:w-7 w-8" />
        </div>
        <div className="2xs:text-sm text-lg tb:text-xl lg:text-2xl">
          {renderTitle()}
        </div>
      </header>
    </>
  );
};

export default Header;

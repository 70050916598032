import React from 'react';
import Header from '../../components/Header/Header';
import logo from '../../assets/images/main-logo-gif.gif';

const About = () => {
  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="서비스 소개" />
      <div className="w-full h-auto relative flex flex-col pt-10 tb:pt-16 lg:pt-20 pb-8 items-center justify-center">
        <div className="flex justify-center items-center">
          <img
            src={logo}
            alt=""
            className="2xs:w-[116px] xs:w-[136px] 2sm:w-[164px] sm:w-[176px] tb:w-[228px] lg:w-[264px] h-auto"
          />
        </div>
        <div className="w-full h-auto leading-[150%] 2xs:px-[10px] 2xs:py-4 xs:px-[18px] xs:py-6 2sm:px-[24px] 2sm:py-6 sm:px-[36px] sm:py-12 tb:px-[100px] tb:py-12 lg:px-[20px] lg:py-20 xl:px-[40px] text-center text-[32px] 2xs:text-[10px] xs:text-[14px] 2sm:text-[16px] sm:text-[22px] tb:text-[26px] font-MainFont text-black flex flex-col justify-center items-center relative">
          <p className="2xs:mb-3 xs:mb-4 2sm:mb-4 sm:mb-6 tb:mb-8 mb-12 text-[40px] 2xs:text-[14px] xs:text-[18px] 2sm:text-[20px] sm:text-[28px] tb:text-[32px]">
            🎉 생카 정보는, 생카데이로 한 번에! 🎂✨
          </p>
          <br />
          그동안 하나씩 생카 이벤트,
          <br />
          검색하고 찾아보고 메모하는 일은 힘들었죠?
          <span className="text-[2px] 2xs:text-[8px] xs:text-[12px] 2sm:text-[12px] sm:text-[16px] tb:text-[20px] text-slate-500">
            (네 그 사람이 저예요...)
          </span>{' '}
          <br />
          생카데이를 통해 한 번에 이벤트를 확인하고,
          <br />
          지도로 편하게 위치를 찾아보세요!
          <br />
          <br />
          현재 서비스는 MVP 초기 버전으로, <br />
          2024년 2월에 생일인 세븐틴 멤버 3명 <br />
          <span className="text-blue-400 my-1 2xs:text-[12px] xs:text-[16px] 2sm:text-[18px] sm:text-[24px] tb:text-[28px] text-[36px]">
            디노, <span className="text-pink-300">도겸,</span> 버논
          </span>
          이렇게 3명 중심으로 운영합니다.
          <br />
          <br />
          향후에는 서비스를 확장할 계획이 있으니,
          <br />
          SNS 공유 많이 해주시고 모두 많은 관심 부탁드립니다🎊💖
        </div>
        <div>
          <a
            href="https://www.instagram.com/mei.devdance/"
            className="mt-12 tb:mt-16 lg:mt-20"
            rel="noreferrer"
            target="_blank"
          >
            <button className="bg-[#454545] px-4 py-2 text-white rounded-2xl font-MainFont">
              사용 가이드 바로가기
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;

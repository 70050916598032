import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { sortedCafes } from '../../libs/cafes';
import Header from '../../components/Header/Header';
import markerImg from '../../assets/images/logo-marker.png';
import markerHomeImg from '../../assets/images/logo-marker-home.png';
import InfoModal from '../../components/InfoModal/InfoModal';
// import logo from '../../assets/images/main-logo-gif.gif';

const { kakao } = window;

const img = new Image();
img.src = '../../assets/images/main-logo-gif.gif';

const infoEndDate = dateString => {
  const endDateString = dateString.split('~')[1].trim();
  const [year, month, day] = endDateString.split('.').map(Number);
  return new Date(year + 2000, month - 1, day);
};

// // 이미지 프리로딩
// function preloadImages(images, callback) {
//   let loaded = 0;
//   images.forEach(imagePath => {
//     const img = new Image();
//     img.onload = () => {
//       loaded++;
//       if (loaded === images.length) {
//         callback();
//       }
//     };
//     img.src = imagePath;
//   });
// }

const Main = () => {
  // eslint-disable-next-line
  // const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.554931928869735, 126.92684298008352),
      level: 4,
    };

    const map = new kakao.maps.Map(container, options);

    function displayMarker(locPosition, message) {
      const markerImage = new kakao.maps.MarkerImage(
        markerHomeImg,
        new kakao.maps.Size(32, 56),
        { offset: new kakao.maps.Point(16, 64) },
      );

      const marker = new kakao.maps.Marker({
        map: map,
        position: locPosition,
        image: markerImage,
      });

      marker.setMap(map);

      let overlay;

      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'wrap-home';
      contentWrapper.style.cursor = 'pointer';

      const infoDiv = document.createElement('div');
      infoDiv.className = 'info';

      const titleDiv = document.createElement('div');
      titleDiv.className = 'home-title';
      titleDiv.innerHTML = '현위치';

      titleDiv.addEventListener('click', () => {
        const mapStatus = overlay.getMap();
        if (mapStatus) {
          overlay.setMap(null);
        } else {
          overlay.setMap(map);
        }
      });

      infoDiv.appendChild(titleDiv);

      const bodyDiv = document.createElement('div');
      bodyDiv.className = 'body';

      infoDiv.appendChild(bodyDiv);

      contentWrapper.appendChild(infoDiv);

      overlay = new kakao.maps.CustomOverlay({
        content: contentWrapper,
        map: map,
        position: marker.getPosition(),
      });

      kakao.maps.event.addListener(marker, 'click', function () {
        const mapStatus = overlay.getMap();
        if (mapStatus) {
          overlay.setMap(null);
        } else {
          overlay.setMap(map);
        }
      });
      map.setCenter(locPosition);
    }

    // 현위치 마커
    function currentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const lat = position.coords.latitude,
            lon = position.coords.longitude;

          const locPosition = new kakao.maps.LatLng(lat, lon);

          displayMarker(locPosition);
          const initialPosition = new kakao.maps.LatLng(
            37.554931928869735,
            126.92684298008352,
          );
          map.setCenter(initialPosition);
        });
      } else {
        const initialPosition = new kakao.maps.LatLng(
          37.554931928869735,
          126.92684298008352,
        );

        displayMarker(initialPosition);
      }
      return true;
    }

    currentLocation();

    // 토스트는 처음 접속 시에만 띄우기
    const hasToast = localStorage.getItem('hasToast');
    if (!hasToast) {
      toast.success(
        '세븐틴 2월즈 생일카페 업데이트 완료!\n생카데이 리뷰 설문조사 진행중\nSNS 참고해주세요!',
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          style: {
            color: 'black',
            fontWeight: 500,
            background: 'rgba(256, 256, 256, 1)',
            borderRadius: '10px',
          },
        },
      );

      // 토스트가 이미 표시되었다는 플래그를 localStorage에 설정
      localStorage.setItem('hasToast', 'true');
    }

    // const mainImagePaths = cafes.map(cafe => cafe.mainSrc);
    // preloadImages(mainImagePaths);

    // // 이미지 프리로딩 실행
    // preloadImages(mainImagePaths, () => {
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1700);
    // });

    // 카페 정보를 날짜 늦은 순으로 정렬

    sortedCafes.forEach(cafe => {
      let endDate = infoEndDate(cafe.date);
      // const currentDate = new Date();
      // currentDate.setHours(0, 0, 0, 0);
      // console.log(startDate, currentDate);

      if (
        endDate.toString() === infoEndDate('date~24.02.11').toString() ||
        endDate.toString() === infoEndDate('date~24.02.18').toString()
      ) {
        const markerImage = new kakao.maps.MarkerImage(
          markerImg,
          new kakao.maps.Size(32, 56),
          { offset: new kakao.maps.Point(16, 64) },
        );

        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(cafe.latitude, cafe.longitude),
          image: markerImage,
        });

        marker.setMap(map);

        let overlay;

        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'wrap';
        contentWrapper.style.cursor = 'pointer';

        const infoDiv = document.createElement('div');
        infoDiv.className = 'info';

        const titleDiv = document.createElement('div');
        titleDiv.className = 'title';
        titleDiv.innerHTML = cafe.name;

        const toggleMarkerBtn = document.createElement('button');
        toggleMarkerBtn.className = 'close';
        toggleMarkerBtn.innerHTML = 'x';
        toggleMarkerBtn.addEventListener('click', () => {
          const mapStatus = overlay.getMap();
          if (mapStatus) {
            overlay.setMap(null);
          } else {
            overlay.setMap(map);
          }
        });

        titleDiv.appendChild(toggleMarkerBtn);
        infoDiv.appendChild(titleDiv);

        const bodyDiv = document.createElement('div');
        bodyDiv.className = 'body';
        bodyDiv.onclick = () => (window.location.href = `/cafe/${cafe.id}`);

        const imgDiv = document.createElement('div');
        imgDiv.className = 'img';
        const imgElement = document.createElement('img');
        imgElement.src = cafe.mainSrc;
        imgDiv.appendChild(imgElement);
        bodyDiv.appendChild(imgDiv);
        infoDiv.appendChild(bodyDiv);

        contentWrapper.appendChild(infoDiv);

        overlay = new kakao.maps.CustomOverlay({
          content: contentWrapper,
          map: map,
          position: marker.getPosition(),
        });

        kakao.maps.event.addListener(marker, 'click', function () {
          const mapStatus = overlay.getMap();
          if (mapStatus) {
            overlay.setMap(null);
          } else {
            overlay.setMap(map);
          }
        });
      }
    });
  }, []);

  return (
    <div>
      <Header />
      <ToastContainer closeOnClick limit={1} />

      {isModalOpen && (
        <div ref={modalRef}>
          <InfoModal shown={isModalOpen} onHide={() => setIsModalOpen(false)} />
        </div>
      )}
      <div className="flex w-full justify-center">
        <div id="map" className="w-full min-h-screen">
          {/* {loading ? ( */}
          {/* <div className="loading-animation">
            <img
              src={logo}
              alt=""
              className="2xs:w-20 2xs:h-20 xs:w-24 xs:h-24 2sm:w-28 2sm:h-28 sm:w-32 sm:h-32 tb:w-40 tb:h-40 lg:w-48 lg:h-48"
            />
          </div> */}
          {/* ) : (
            <div />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Main;

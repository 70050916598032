export const cafes = [
  {
    id: 0,
    link: '/info/0',
    name: '몰리스피크닉',
    position: '홍대입구역 7, 8번 출구 203m 거리',
    address: '서울 마포구 와우산로29바길 10 2층',
    date: '24.02.09~24.02.11',
    time: '12:00~20:00',
    host: 'AquariusDino211',
    mainSrc:
      'https://pbs.twimg.com/media/GErvAn8a0AAQ4j6?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GErvAn9awAA-HbU?format=jpg&name=large',
    subSrc2:
      'https://pbs.twimg.com/media/GErvAn8bMAACxXn?format=jpg&name=large',
    subSrc3: '',
    latitude: 37.5560204,
    longitude: 126.9260893,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 1,
    link: '/info/1',
    name: '카페쿠키드',
    position: '합정역 3번 출구 440m 거리',
    address: '서울 마포구 어울마당로5길 25 1층',
    date: '24.02.09~24.02.11',
    time: '11:00~20:00 (11일은 18:00)',
    host: 'seesun0211',
    mainSrc:
      'https://pbs.twimg.com/media/GEhB7N6b0AAL6Fr?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GEhB7N7aUAA02S6?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5493079,
    longitude: 126.919082,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 2,
    link: '/info/2',
    name: '마린커피',
    position: '신용산역 2번 출구 583m 거리',
    address: '서울 용산구 한강대로10길 11-48 1층',
    date: '24.02.10~24.02.12',
    time: '11:00~19:00',
    host: 'cheese21199',
    mainSrc:
      'https://pbs.twimg.com/media/GEHWTakasAAqz3n?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GEHWTagbgAA2voP?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5244908,
    longitude: 126.9647788,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 3,
    link: '/info/3',
    name: '갤러리카페 바이아스',
    position: '홍대입구역 2번 출구 100m 거리',
    address: '서울 마포구 와우산로35길 53 2층',
    date: '24.02.08~24.02.12',
    time: '11:00~20:00',
    host: 'eyesoflove99',
    mainSrc:
      'https://pbs.twimg.com/media/GEDbjeXbUAAmnAq?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEDbjeXawAAUuYH?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.556398533428236,
    longitude: 126.92789196968079,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 4,
    link: '/info/4',
    name: '복숭아꽃',
    position: '상수역 1번 출구 361m 거리',
    address: '서울 마포구 양화로6길 99-5',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00 (18일은 18:00)',
    host: 'dkcandk',
    mainSrc:
      'https://pbs.twimg.com/media/GD8rWvEakAAkxdI?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GD8rYEabUAA1kLA?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5486172,
    longitude: 126.9198273,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 5,
    link: '/info/5',
    name: '카페몽글 하늘점',
    position: '홍대입구역 7번 출구 303m 거리',
    address: '서울 마포구 와우산로29라길 21 3층',
    date: '24.02.17~24.02.18',
    time: '11:30~18:30',
    host: 'DK_birthtagram',
    mainSrc:
      'https://pbs.twimg.com/media/GCu178VbYAAfGCh?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GCu18LzasAAtKdD?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.554854,
    longitude: 126.9269056,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 6,
    link: '/info/6',
    name: '카페 지금여기',
    position: '홍대입구역 9번 출구 493m 거리',
    address: '서울 마포구 양화로16길 30-5 3층',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00',
    host: 'dk_is_warmpuppy',
    mainSrc:
      'https://pbs.twimg.com/media/GC6NwnjawAAsqmq?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GC6NxGOacAAljMs?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.552711383920496,
    longitude: 126.92120656371117,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 7,
    link: '/info/7',
    name: '파티세리로그',
    position: '상수역 1번 출구 399m 거리',
    address: '서울 마포구 양화로6길 86 1층',
    date: '24.02.16~24.02.18',
    time: '10:30~17:30 (16일은 12:30~19:30)',
    host: '0218_dkvernon',
    mainSrc:
      'https://pbs.twimg.com/media/GCu6ChQagAA30nC?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GCu6C_CboAA2D6J?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.54824148056705,
    longitude: 126.91884353756905,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 8,
    link: '/info/8',
    name: '맘앤키즈',
    position: '서부정류장역 3번 출구 77m 거리',
    address: '대구 남구 월배로 492',
    date: '24.02.17~24.02.18',
    time: '10:00~18:00',
    host: 'svtsvt_boo',
    mainSrc:
      'https://pbs.twimg.com/media/GESiDkxaEAAViIr?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GESiDkxasAAc1ZU?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.8365327,
    longitude: 128.5569769,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 9,
    link: '/info/9',
    name: '하이타운 서울',
    position: '홍대입구역 7번 출구 305m 거리',
    address: '서울 마포구 와우산로29다길 10',
    date: '24.02.16~24.02.18',
    time: '12:00~19:00',
    host: 'C0UNTD0WNT0MARS',
    mainSrc:
      'https://pbs.twimg.com/media/GEWRLZPbAAE0q35?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEWRLZRawAAxG_K?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5551137,
    longitude: 126.9276468,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 10,
    link: '/info/10',
    name: '틸다제이',
    position: '상수역 1번 출구 129m 거리',
    address: '서울 마포구 독막로15길 3-6 2층',
    date: '24.02.17~24.02.18',
    time: '11:00~19:00',
    host: 'M0V1ECLUB',
    mainSrc:
      'https://pbs.twimg.com/media/GBdigwRbMAAQcUL?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GBdih06aQAEkSkF?format=jpg&name=4096x4096',
    subSrc2:
      'https://pbs.twimg.com/media/GBdih1VbsAAbWve?format=jpg&name=4096x4096',
    subSrc3: '',
    latitude: 37.54827969999999,
    longitude: 126.9217777,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 11,
    link: '/info/11',
    name: '카페쿠키드',
    position: '합정역 3번 출구 440m 거리',
    address: '서울 마포구 어울마당로5길 25 1층',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00 (18일은 18:00)',
    host: 'saikai2024dk',
    mainSrc:
      'https://pbs.twimg.com/media/GDEb5DHa4AA4P_g?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDEb5DGa4AA02rk?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5493079,
    longitude: 126.919082,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 12,
    link: '/info/12',
    name: '해피벌스데이',
    position: '홍대입구역 3번 출구 360m 거리',
    address: '서울 마포구 동교로34길 8 2층',
    date: '24.02.11~24.02.12',
    time: '12:00~17:00',
    host: 'hosha0526',
    mainSrc:
      'https://pbs.twimg.com/media/GFPT-4IbgAAiI5E?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFPT_F2bUAA-O58?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5609182,
    longitude: 126.9247985,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 13,
    link: '/info/13',
    name: '부산 모멘트',
    position: '서면역 2번 출구 409m 거리',
    address: '부산 부산진구 신천대로50번길 66 4층',
    date: '24.02.11~24.02.12',
    time: '12:00~17:00',
    host: 'hosha0526',
    mainSrc:
      'https://pbs.twimg.com/media/GFPT-4IbgAAiI5E?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFPT_F2bUAA-O58?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1536592,
    longitude: 129.0600483,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 14,
    link: '/info/14',
    name: '부산 카페뮤즈',
    position: '전포역 7번 출구 295m 거리',
    address: '부산 부산진구 전포대로209번길 39-4 2층',
    date: '24.02.11~24.02.12',
    time: '11:00~19:00',
    host: 'cafe__MUSE',
    mainSrc:
      'https://pbs.twimg.com/media/GFA_bTpaYAA-mUw?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFA_bTMbYAA90fh?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1551723,
    longitude: 129.0628983,
    bday_name: 'DINO, DK, VERNON',
    bday_date: '1999.02.11, 1997.02.18, 1998.02.18',
  },
  {
    id: 15,
    link: '/info/15',
    name: '대전 세모네모',
    position: '중앙로역 8번 출구 25m 거리',
    address: '대전 중구 대종로 502 1층',
    date: '24.02.08~24.02.11',
    time: '11:00~18:00',
    host: 'BdaySeventeen',
    mainSrc:
      'https://pbs.twimg.com/media/GFJjGoxbQAACdgo?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFJjGo0bAAAxlnF?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 36.3291987,
    longitude: 127.4256126,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 16,
    link: '/info/16',
    name: '대구 카페퐁당',
    position: '명덕역 4번 출구 197m 거리',
    address: '대구 남구 중앙대로49길 14 1층',
    date: '24.02.08~24.02.11',
    time: '11:00~19:00',
    host: 'BdaySeventeen',
    mainSrc:
      'https://pbs.twimg.com/media/GFJjGoxbQAACdgo?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFJjGo0bAAAxlnF?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.855533,
    longitude: 128.5897733,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 17,
    link: '/info/17',
    name: '부산 바닐라808',
    position: '개금역 1번 출구 10m 거리',
    address:
      '부산 부산진구 가야대로 450 금강펜테리움더스퀘어아파트 상가동 116호',
    date: '24.02.10~24.02.11',
    time: '11:00~18:00',
    host: 'BdaySeventeen',
    mainSrc:
      'https://pbs.twimg.com/media/GFJjGoxbQAACdgo?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFJjGo0bAAAxlnF?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1525214,
    longitude: 129.0209734,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 18,
    link: '/info/18',
    name: '창원 커피앤스토리',
    position: '경남 창원시 성산구',
    address: '경남 창원시 성산구 창이대로695번길 8',
    date: '24.02.10~24.02.12',
    time: '11:00~18:00',
    host: 'BdaySeventeen',
    mainSrc:
      'https://pbs.twimg.com/media/GFJjGoxbQAACdgo?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFJjGo0bAAAxlnF?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.2231446,
    longitude: 128.7017634,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 19,
    link: '/info/19',
    name: 'RGB 포토스튜디오',
    position: '홍대입구역 7번 출구 384m 거리',
    address: '서울 마포구 와우산로27길 28',
    date: '24.02.09~24.02.12',
    time: '프레임 이벤트',
    host: '2IIproject',
    mainSrc:
      'https://pbs.twimg.com/media/GE7BVGLagAA0eUU?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GE7BVGMbsAAqmmh?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5544919,
    longitude: 126.926932,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 20,
    link: '/info/20',
    name: '카페 키로',
    position: '합정역 3번 출구 441m 거리',
    address: '서울 마포구 독막로7길 49 2층',
    date: '24.02.11~24.02.12',
    time: '12:00~20:00',
    host: '2024__svtHBD',
    mainSrc:
      'https://pbs.twimg.com/media/GE52jtBbwAA4zHd?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GE52j85aUAE6om3?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.54999979999999,
    longitude: 126.9197017,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 21,
    link: '/info/21',
    name: '홍대네컷 홍대거리점',
    position: '합정역 3번 출구 441m 거리',
    address: '서울 마포구 어울마당로 40 101호',
    date: '24.02.08~24.02.14',
    time: '프레임 이벤트',
    host: 'D13o0814',
    mainSrc:
      'https://pbs.twimg.com/media/GEw6ZGmbAAA0uNQ?format=jpg&name=large',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5485418,
    longitude: 126.9206756,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 22,
    link: '/info/22',
    name: '채온',
    position: '상수역 1번 출구 212m 거리',
    address: '서울 마포구 와우산로11길 25',
    date: '24.02.11~24.02.12',
    time: '11:30~18:30 (12일은 15:00)',
    host: 'DINOWORLD211',
    mainSrc:
      'https://pbs.twimg.com/media/GEqWiu0a4AAXach?format=jpg&name=medium',
    subSrc1:
      'https://pbs.twimg.com/media/GEqWiu1bUAAPWyr?format=jpg&name=medium',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5486529,
    longitude: 126.9216701,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 23,
    link: '/info/23',
    name: '부산 카페벤',
    position: '자갈치역 7번 출구 186m 거리',
    address: '부산 중구 비프광장로 19',
    date: '24.02.11~24.02.14',
    time: '11:00~20:00',
    host: 'D_Diamond_days',
    mainSrc:
      'https://pbs.twimg.com/media/GELWpk0aAAENDp2?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GELWpkzbMAA59vo?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.0987545,
    longitude: 129.0272987,
    bday_name: 'DINO, DK',
    bday_date: '1999.02.11, 1997.02.18',
  },
  {
    id: 24,
    link: '/info/24',
    name: '브라운시티 로스팅랩',
    position: '합정역 6번 출구 240m 거리',
    address: '서울 마포구 독막로 27',
    date: '24.02.11~24.02.12',
    time: '10:00~22:00',
    host: 'DINO_BD_CAFE',
    mainSrc:
      'https://pbs.twimg.com/media/GDM34onbcAAGqt5?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDM341fbMAASSrT?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5485083,
    longitude: 126.916576,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 25,
    link: '/info/25',
    name: '카페몽글 핑크점',
    position: '홍대입구역 7번 출구 259m 거리',
    address: '서울 마포구 와우산로29라길 11 지하1층',
    date: '24.02.09~24.02.10',
    time: '11:30~18:30',
    host: 'Loveyou__chan',
    mainSrc:
      'https://pbs.twimg.com/media/GELEm3uaoAAKND0?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GELEnzQbAAA7svG?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5551903,
    longitude: 126.9271989,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 26,
    link: '/info/26',
    name: '카페몽글 하늘점',
    position: '홍대입구역 7번 출구 303m 거리',
    address: '서울 마포구 와우산로29라길 21 2층',
    date: '24.02.09~24.02.12',
    time: '11:30~18:30',
    host: 'Baby__dinosaur_',
    mainSrc:
      'https://pbs.twimg.com/media/GEGmN5kbIAARqfP?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEGmO_rbEAAuUCX?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.554854,
    longitude: 126.9269056,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 27,
    link: '/info/27',
    name: '대전 준타라',
    position: '대전 유성구 유성대로217번길 34',
    address: '대전 유성구 유성대로217번길 34',
    date: '24.02.01~24.02.04',
    time: '10:00~18:00',
    host: 'cafe_jun_tara',
    mainSrc:
      'https://pbs.twimg.com/media/GEHA20UaIAAPvXS?format=jpg&name=4096x4096',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 36.3159763,
    longitude: 127.3140026,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 28,
    link: '/info/28',
    name: '하이럽',
    position: '합정역 3번 출구 427m 거리',
    address: '서울 마포구 독막로7길 42 2층',
    date: '24.02.09~24.02.12',
    time: '11:00~20:00 (12일은 18:00)',
    host: 'HBDDINO7',
    mainSrc:
      'https://pbs.twimg.com/media/GDzwaFrbUAAehRV?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDzwanHbMAE-3qr?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.549611,
    longitude: 126.9197412,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 29,
    link: '/info/29',
    name: '카페라래',
    position: '홍대입구역 7번 출구 201m 거리',
    address: '서울 마포구 와우산로29마길 7',
    date: '24.02.09~24.02.12',
    time: '11:00~20:00 (12일은 19:00)',
    host: '2IIproject',
    mainSrc:
      'https://pbs.twimg.com/media/GDO-yYKb0AAUAEY?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GDO-zikasAAaIcH?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5556245,
    longitude: 126.9267936,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 30,
    link: '/info/30',
    name: '메르시쿠키',
    position: '신용산역 2번 출구 589m 거리',
    address: '서울 용산구 한강대로10길 11-44',
    date: '24.02.11~24.02.13',
    time: '11:00~18:00',
    host: 'youth_for_dino',
    mainSrc:
      'https://pbs.twimg.com/media/GC7H-sjXYAAizSc?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GC7H-soWwAAJgQq?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5244679,
    longitude: 126.9647047,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 31,
    link: '/info/31',
    name: '플로이드',
    position: '상수역 1번 출구 150m 거리',
    address: '서울 마포구 독막로15길 3-7 이현S 1층',
    date: '24.02.09~24.02.12',
    time: '12:00~20:00 (12일은 18:00)',
    host: 'DiNo13_',
    mainSrc:
      'https://pbs.twimg.com/media/GC6H3A1acAAtGQV?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GC6H4pcbEAA9B8R?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5482804,
    longitude: 126.9214955,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 32,
    link: '/info/32',
    name: '카페 Beep',
    position: '상수역 1번 출구 266m 거리',
    address: '서울 마포구 와우산로13길 9',
    date: '24.02.03~24.02.04',
    time: '11:00~19:00 (4일은 18:30)',
    host: '25thdino',
    mainSrc:
      'https://pbs.twimg.com/media/GBx_t6qbEAAK1BF?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GBx_t7Ua0AAHQY7?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.54955355896543,
    longitude: 126.9225624203682,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 33,
    link: '/info/33',
    name: '카페 먼로',
    position: '합정역 6번 출구 367m 거리',
    address: '서울 마포구 양화로6길 57 3층',
    date: '24.02.10~24.02.11',
    time: '12:00~23:00 (11일은 19:30)',
    host: 'dinobdparty',
    mainSrc:
      'https://pbs.twimg.com/media/GBjZ7oMa8AARIQA?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GBjZ7nMaIAA4G6_?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5488206,
    longitude: 126.9174111,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 34,
    link: '/info/34',
    name: '봄날 플라워카페',
    position: '신용산역 2번 출구 631m 거리',
    address: '서울 용산구 한강대로10길 11-18 1층',
    date: '24.02.11~24.02.12',
    time: '08:00~20:00',
    host: 'dino240211',
    mainSrc:
      'https://pbs.twimg.com/media/GC1uCQ6bUAEAId4?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GC1uCjFbIAAZMt4?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5241681,
    longitude: 126.9645936,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 35,
    link: '/info/35',
    name: '롱브르378',
    position: '신용산역 2번 출구 574m 거리',
    address: '서울 용산구 한강대로10길 11-54',
    date: '24.02.11~24.02.12',
    time: '11:00~19:00',
    host: 'hbddino',
    mainSrc:
      'https://pbs.twimg.com/media/GCMkbWebYAAMngg?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GCMkbWjasAABYl-?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5245478,
    longitude: 126.9648358,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 36,
    link: '/info/36',
    name: '부산 타임슬래시',
    position: '국제금융센터.부산은행역 4번 출구 220m 거리',
    address: '부산 부산진구 황령대로64번길 25-1 1층',
    date: '24.02.10~24.02.11',
    time: '12:00~20:00',
    host: 'youth_for_dino',
    mainSrc:
      'https://pbs.twimg.com/media/GEA-4N6bMAAJegc?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEA-4N4aIAAWXfD?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.148484,
    longitude: 129.0672348,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 37,
    link: '/info/37',
    name: '부산 베란다',
    position: '중앙역 5번 출구 590m 거리',
    address: '부산 중구 광복중앙로 28',
    date: '24.02.09~24.02.12',
    time: '11:00~19:00 (평일은 12:00~12:00)',
    host: 'c_89_you',
    mainSrc:
      'https://pbs.twimg.com/media/FmLK5LRakAA66DO?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/FmLK56vagAEqvUS?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1017768,
    longitude: 129.0310524,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 38,
    link: '/info/38',
    name: '부산 호야팡야',
    position: '전포역 4번 출구 123m 거리',
    address: '부산 부산진구 전포대로176번길 12-8 1층',
    date: '24.02.10~24.02.11',
    time: '12:00~19:00 (11일은 18:00)',
    host: 'svt_Fairytale13',
    mainSrc:
      'https://pbs.twimg.com/media/Fnsnn-CacAEtHTX?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/Fnsnn-EaQAA-Tio?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.151991,
    longitude: 129.0663397,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 39,
    link: '/info/39',
    name: 'RGB 포토스튜디오',
    position: '합정역 7번 출구 240m 거리',
    address: '서울 마포구 독막로2길 27',
    date: '24.02.09~24.02.12',
    time: '프레임 이벤트',
    host: 'zlakima',
    mainSrc:
      'https://pbs.twimg.com/media/GEctQ-ia4AAbbVG?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEctRoabcAEpi9c?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5472974,
    longitude: 126.9146313,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 40,
    link: '/info/40',
    name: '고수포차 본점',
    position: '합정역 6번 출구 397m 거리',
    address: '서울 마포구 양화로6길 67 2층',
    date: '24.02.07~24.02.11',
    time: '16:00~02:00',
    host: '_feat211',
    mainSrc:
      'https://pbs.twimg.com/media/GCrfyO2bEAAMA65?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GCrfyyjbQAAfEEd?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5487091,
    longitude: 126.9179905,
    bday_name: 'DINO',
    bday_date: '1999.02.11',
  },
  {
    id: 41,
    link: '/info/41',
    name: '뉴욕라츠오베이글스',
    position: '압구정로데오역 5번 출구 397m 거리',
    address: '서울 강남구 압구정로48길 28 1,2층',
    date: '24.02.17~24.02.18',
    time: '08:00~20:00',
    host: 'dkdkbakery',
    mainSrc:
      'https://pbs.twimg.com/media/GDKTvK_aIAA0z0G?format=jpg&name=large',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5267932,
    longitude: 127.036637,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 42,
    link: '/info/42',
    name: '카페 먼로',
    position: '합정역 6번 출구 367m 거리',
    address: '서울 마포구 양화로6길 57 3층',
    date: '24.02.17~24.02.18',
    time: '11:00~20:00 (18일은 19:00)',
    host: 'HAPPY_DK_DAY218',
    mainSrc:
      'https://pbs.twimg.com/media/GCv4u8VbMAA_K8V?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GCv4vG4bUAAdraI?format=jpg&name=large',
    subSrc2:
      'https://pbs.twimg.com/media/GCv4vSbbgAAigHT?format=jpg&name=large',
    subSrc3: '',
    latitude: 37.5488206,
    longitude: 126.9174111,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 43,
    link: '/info/43',
    name: '카페소공원',
    position: '합정역 3번 출구 315m 거리',
    address: '서울 마포구 어울마당로5길 52',
    date: '24.02.16~24.02.18',
    time: '11:30~20:00',
    host: 'DokiDoki_1ove',
    mainSrc:
      'https://pbs.twimg.com/media/GDPdTlAacAEJfs9?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDPdTj3bEAAt8_O?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5501377,
    longitude: 126.9180095,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 44,
    link: '/info/44',
    name: '카페비비드',
    position: '합정역 3번 출구 345m 거리',
    address: '서울 마포구 독막로3길 28-18',
    date: '24.02.17~24.02.18',
    time: '12:00~20:00 (18일은 19:00)',
    host: 'HAPPYKYEOMPUDAY',
    mainSrc:
      'https://pbs.twimg.com/media/GDlDdmYakAAagD0?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDlDdmYa4AASY9d?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.549706,
    longitude: 126.9178874,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 45,
    link: '/info/45',
    name: '대구 카페퐁당',
    position: '명덕역 4번 출구 197m 거리',
    address: '대구 남구 중앙대로49길 14 1층',
    date: '24.02.15~24.02.18',
    time: '11:00~19:00',
    host: 'svt_cup_event',
    mainSrc:
      'https://pbs.twimg.com/media/GD2mrrPawAAvUS7?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GD2mrrNaAAAvf7f?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.855533,
    longitude: 128.5897733,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 46,
    link: '/info/46',
    name: '대전 세모네모',
    position: '중앙로역 8번 출구 25m 거리',
    address: '대전 중구 대종로 502 1층',
    date: '24.02.16~24.02.18',
    time: '11:00~18:00',
    host: 'svt_cup_event',
    mainSrc:
      'https://pbs.twimg.com/media/GD2mrrPawAAvUS7?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GD2mrrNaAAAvf7f?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 36.3291987,
    longitude: 127.4256126,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 47,
    link: '/info/47',
    name: '창원 커피앤스토리',
    position: '경남 창원시 성산구',
    address: '경남 창원시 성산구 창이대로695번길 8',
    date: '24.02.15~24.02.18',
    time: '10:00~19:00',
    host: 'svt_cup_event',
    mainSrc:
      'https://pbs.twimg.com/media/GD2mrrPawAAvUS7?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GD2mrrNaAAAvf7f?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.2231446,
    longitude: 128.7017634,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 48,
    link: '/info/48',
    name: '몰리스피크닉',
    position: '홍대입구역 7, 8번 출구 203m 거리',
    address: '서울 마포구 와우산로29바길 10 2층',
    date: '24.02.17~24.02.18',
    time: '12:00~20:00',
    host: 'DawnKiss97218',
    mainSrc:
      'https://pbs.twimg.com/media/GECgUZgaEAA9Z-m?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GECgX7ubMAAEDB6?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5560204,
    longitude: 126.9260893,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 49,
    link: '/info/49',
    name: '체리시',
    position: '홍대입구역 7번 출구 320m 거리',
    address: '서울 마포구 와우산로29바길 10 2층',
    date: '24.02.17~24.02.18',
    time: '12:00~20:00 (18일은 18:00)',
    host: 'itsDKt_218',
    mainSrc:
      'https://pbs.twimg.com/media/GEHVpSqbYAAg52D?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GEHVqBObwAAVPwq?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.55498730000001,
    longitude: 126.9275367,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 50,
    link: '/info/50',
    name: '미드메이 봄날',
    position: '홍대입구역 6번 출구 185m 거리',
    address: '서울 마포구 신촌로2안길 40 위저드빌1 지하1층',
    date: '24.02.17~24.02.18',
    time: '11:00~19:00',
    host: 'BSSvsNWSS_218',
    mainSrc:
      'https://pbs.twimg.com/media/GEIaaEfbwAAb2nQ?format=jpg&name=large',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5568055,
    longitude: 126.9290989,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 51,
    link: '/info/51',
    name: '카페식스',
    position: '홍대입구역 7번 출구 288m 거리',
    address: '서울 마포구 와우산로29라길 19 2층',
    date: '24.02.17~24.02.18',
    time: '12:00~19:00 (18일은 18:00)',
    host: 'DK_moment_218',
    mainSrc:
      'https://pbs.twimg.com/media/GESEPIqbkAAyrMU?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GESEPItb0AAKZbU?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5549658,
    longitude: 126.9270027,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 52,
    link: '/info/52',
    name: '대구 노스폴',
    position: '중앙로역 2번 출구 223m 거리',
    address: '대구 중구 중앙대로 392 동성빌딩 4층',
    date: '24.02.15~24.02.18',
    time: '13:00~20:00',
    host: 'jelimonmon',
    mainSrc:
      'https://pbs.twimg.com/media/GEbp3ftbIAA_A8u?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEbp3fqbIAAn7dp?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.8680215,
    longitude: 128.5937139,
    bday_name: 'DINO, DK, VERNON',
    bday_date: '1999.02.11, 1997.02.18, 1998.02.18',
  },
  {
    id: 53,
    link: '/info/53',
    name: '부산 베란다',
    position: '중앙역 5번 출구 590m 거리',
    address: '부산 중구 광복중앙로 28',
    date: '24.02.16~24.02.18',
    time: '11:00~19:00 (평일은 12:00~12:00)',
    host: '_svtdodang',
    mainSrc:
      'https://pbs.twimg.com/media/GEiSTSpbcAAO2ux?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEiSTSobkAE22py?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1017768,
    longitude: 129.0310524,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 54,
    link: '/info/54',
    name: '울산 카페온',
    position: '울산 남구 월평로 191 1층',
    address: '울산 남구 월평로 191 1층',
    date: '24.02.16~24.02.18',
    time: '10:00~20:00 (18일은 11:00~20:00)',
    host: 'CAFEON_0191',
    mainSrc:
      'https://pbs.twimg.com/media/GEhqg1wbgAEwwFg?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEhqg1uaAAAicWX?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.54587010000001,
    longitude: 129.3259169,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 55,
    link: '/info/55',
    name: '대구 카페데이즈',
    position: '중앙로역 3번 출구 170m 거리',
    address: '대구 중구 동성로 59 2층',
    date: '24.02.15~24.02.17',
    time: '12:00~20:00',
    host: 'SVT_HBDEVENT',
    mainSrc:
      'https://pbs.twimg.com/media/GEcYMqua8AAl9H_?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEcYMqxbYAAtbDe?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.8720677,
    longitude: 128.5955256,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 56,
    link: '/info/56',
    name: '하이럽',
    position: '합정역 3번 출구 427m 거리',
    address: '서울 마포구 독막로7길 42 2층',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00 (18일은 18:00)',
    host: 'dkcandk',
    mainSrc:
      'https://pbs.twimg.com/media/GEHP6cMacAEklwe?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GEHP6cKbIAAEYfu?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.549611,
    longitude: 126.9197412,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 57,
    link: '/info/57',
    name: '부산 포토레이블 남포점',
    position: '자갈치역 7번 출구 320m 거리',
    address: '부산 중구 남포길 9 1층',
    date: '24.02.09~24.02.18',
    time: '프레임 이벤트',
    host: 'hanjeong_1004',
    mainSrc:
      'https://pbs.twimg.com/media/GErjKzkbIAAK1te?format=jpg&name=large',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.0989723,
    longitude: 129.0298259,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 58,
    link: '/info/58',
    name: '롱브르378',
    position: '신용산역 2번 출구 574m 거리',
    address: '서울 용산구 한강대로10길 11-54',
    date: '24.02.17~24.02.19',
    time: '11:00~19:00 (19일은 18:00)',
    host: '218carnival',
    mainSrc:
      'https://pbs.twimg.com/media/GEw3hCgb0AAeCij?format=jpg&name=medium',
    subSrc1:
      'https://pbs.twimg.com/media/GEw3hCiasAAMax0?format=jpg&name=medium',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5245478,
    longitude: 126.9648358,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 59,
    link: '/info/59',
    name: '폴라스튜디오 합정점',
    position: '합정역 3번 출구 220m 거리',
    address: '서울 마포구 독막로3길 18 1층',
    date: '24.02.16~24.02.18',
    time: '프레임 이벤트',
    host: 'dayfordk',
    mainSrc:
      'https://pbs.twimg.com/media/GE0zXq8bMAAlcC8?format=jpg&name=large',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.54907480000001,
    longitude: 126.9169034,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 60,
    link: '/info/60',
    name: '스핀오프',
    position: '홍대입구역 6번 출구 283m 거리',
    address: '서울 마포구 와우산로 161 성경빌딩 2층',
    date: '24.02.16~24.02.18',
    time: '12:00~20:00 (18일은 18:00)',
    host: 'bestpitcher_LSM',
    mainSrc:
      'https://pbs.twimg.com/media/GEYVJQKaEAAXGJB?format=jpg&name=900x900',
    subSrc1:
      'https://pbs.twimg.com/media/GEYVNO9bwAA1Fsn?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5553077,
    longitude: 126.9303115,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 61,
    link: '/info/61',
    name: '카페 키로',
    position: '합정역 3번 출구 441m 거리',
    address: '서울 마포구 독막로7길 49 2층',
    date: '24.02.16~24.02.18',
    time: '',
    host: '_cherishdk',
    mainSrc:
      'https://pbs.twimg.com/media/GFGIhLgb0AAUvNw?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFGIhLdbMAA7J5w?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.54999979999999,
    longitude: 126.9197017,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 62,
    link: '/info/62',
    name: '마린커피',
    position: '신용산역 2번 출구 583m 거리',
    address: '서울 용산구 한강대로10길 11-48 1층',
    date: '24.02.16~24.02.18',
    time: '',
    host: '_cherishdk',
    mainSrc:
      'https://pbs.twimg.com/media/GFGIhLgb0AAUvNw?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFGIhLdbMAA7J5w?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5244908,
    longitude: 126.9647788,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 63,
    link: '/info/63',
    name: '카페333',
    position: '홍대입구역 7번 출구 257m 거리',
    address: '서울 마포구 와우산로29라길 12',
    date: '24.02.16~24.02.18',
    time: '11:00~21:00 (18일은 18:00)',
    host: 'thxDKHBD',
    mainSrc:
      'https://pbs.twimg.com/media/GEdDvvrXEAAF8Qq?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GEdDwl7WUAERl9G?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5552902,
    longitude: 126.9270282,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 64,
    link: '/info/64',
    name: '뽀빠이피자클럽 서교점',
    position: '홍대입구역 7번 출구 281m 거리',
    address: '서울 마포구 와우산로29길 32',
    date: '24.02.17~24.02.18',
    time: '14:00~22:00',
    host: 'pizzaloverboy_',
    mainSrc:
      'https://pbs.twimg.com/media/GFAnnBfbkAAsaDw?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFAnnvhaQAAAHAd?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5554057,
    longitude: 126.9283249,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 65,
    link: '/info/65',
    name: '인천 카페미미',
    position: '제물포역 1번 출구 882m 거리',
    address: '인천 미추홀구 경인로 45 1층',
    date: '24.02.16~24.02.17',
    time: '14:00~19:00/11:00~17:00',
    host: '1_w0o',
    mainSrc:
      'https://pbs.twimg.com/media/GFJYECFbsAAcJDp?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFJYECHbEAAuaF2?format=jpg&name=large',
    subSrc2:
      'https://pbs.twimg.com/media/GFJYECEa0AAY3we?format=jpg&name=large',
    subSrc3: '',
    latitude: 37.4645408,
    longitude: 126.6480216,
    bday_name: 'DK, VERNON',
    bday_date: '1997.02.18, 1998.02.18',
  },
  {
    id: 66,
    link: '/info/66',
    name: '카페 어싸이드',
    position: '홍대입구역 6번 출구 169m 거리',
    address: '서울 마포구 신촌로2안길 35 1층',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00 (18일은 19:00)',
    host: 'bunny0218_jagi',
    mainSrc:
      'https://pbs.twimg.com/media/GFY2gxeaEAAHp23?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GFY2gxeacAADAPR?format=jpg&name=4096x4096',
    subSrc2:
      'https://pbs.twimg.com/media/GFY2gxhbAAA_kjr?format=jpg&name=4096x4096',
    subSrc3: '',
    latitude: 37.5570267,
    longitude: 126.9290549,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 67,
    link: '/info/67',
    name: '카페니타',
    position: '홍대입구역 4번 출구 456m 거리',
    address: '서울 마포구 와우산로29라길 20 1층',
    date: '24.02.16~24.02.18',
    time: '12:00~19:00 (18일은 18:00)',
    host: 'DKdaycafe',
    mainSrc:
      'https://pbs.twimg.com/media/GFe92V6bcAAwX0P?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GFe921takAAHJ_o?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5550479,
    longitude: 126.9268168,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 68,
    link: '/info/68',
    name: '카페보니',
    position: '합정역 7번 출구 335m 거리',
    address: '서울 마포구 독막로4길 7',
    date: '24.02.16~24.02.18',
    time: '12:00~20:00',
    host: 'DK_DayofKing',
    mainSrc:
      'https://pbs.twimg.com/media/F5F1nrra0AAvUkY?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GE6biJGasAAFg0z?format=jpg&name=900x900',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.547813,
    longitude: 126.9169954,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 69,
    link: '/info/69',
    name: '고수포차 본점',
    position: '합정역 6번 출구 397m 거리',
    address: '서울 마포구 양화로6길 67 2층',
    date: '24.02.16~24.02.18',
    time: '17:00~02:00\n16:00~02:00\n16:00~01:00',
    host: 'DK_DayofKing',
    mainSrc:
      'https://pbs.twimg.com/media/F5F1nrra0AAvUkY?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GE6biaqaQAAiTPW?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5487091,
    longitude: 126.9179905,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 70,
    link: '/info/70',
    name: '고수포차 상수본점',
    position: '상수역 4번 출구 114m 거리',
    address: '서울 마포구 독막로 76-1 한주빌딩 1층',
    date: '24.02.16~24.02.18',
    time: '',
    host: 'guri_guri_218',
    mainSrc:
      'https://pbs.twimg.com/media/GDuTOZLaUAA1sJr?format=jpg&name=medium',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5475708,
    longitude: 126.921904,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 71,
    link: '/info/71',
    name: '카페몽글 핑크점',
    position: '홍대입구역 7번 출구 259m 거리',
    address: '서울 마포구 와우산로29라길 11 지하1층',
    date: '24.02.16~24.02.18',
    time: '11:30~18:30',
    host: 'Love__DK',
    mainSrc:
      'https://pbs.twimg.com/media/GFED2OvbIAAQRKO?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFED3IbaAAAJXik?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5551903,
    longitude: 126.9271989,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 72,
    link: '/info/72',
    name: '시애틀에스프레소',
    position: '신용산역 2번 출구 594m 거리',
    address: '서울 용산구 한강대로11길 4',
    date: '24.02.09~24.02.11',
    time: '12:00~18:00',
    host: 'svt13_0526_',
    mainSrc:
      'https://pbs.twimg.com/media/GFYeGaAa8AAtkVx?format=jpg&name=medium',
    subSrc1: '',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5250318,
    longitude: 126.9633796,
    bday_name: 'DINO, DK, VERNON',
    bday_date: '1999.02.11, 1997.02.18, 1998.02.18',
  },
  {
    id: 73,
    link: '/info/73',
    name: '브라운시티 본점',
    position: '상수역 2번 출구 498m 거리',
    address: '서울 마포구 와우산로 88-1 2층',
    date: '24.02.16~24.02.18',
    time: '12:00~19:00',
    host: 'MeetVernon_218',
    mainSrc:
      'https://pbs.twimg.com/media/GDVJSkmWMAA9ozY?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GDVJSkpXUAAIuS1?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.552162,
    longitude: 126.9239189,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 74,
    link: '/info/74',
    name: '히치하이킹 클럽',
    position: '상수역 1번 출구 390m 거리',
    address: '서울 마포구 독막로9길 3-4',
    date: '24.02.16~24.02.18',
    time: '12:00~20:00 (18일은 18:00)',
    host: 'triptoVermoon',
    mainSrc:
      'https://pbs.twimg.com/media/GDEtNWMbMAAbzZw?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GDEtNWJbgAA7JwD?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5487311,
    longitude: 126.9194082,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 75,
    link: '/info/75',
    name: '러비니돌',
    position: '홍대입구역 5번 출구 118m 거리',
    address: '서울 마포구 신촌로2안길 20 B02호',
    date: '24.02.16~24.02.18',
    time: '11:00~20:00 (18일은 19:00)',
    host: 'Astronaut_for_V',
    mainSrc:
      'https://pbs.twimg.com/media/GFAIOqXbIAAz091?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFAIOqYbgAAiADj?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5572276,
    longitude: 126.928111,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 76,
    link: '/info/76',
    name: '메르시쿠키',
    position: '신용산역 2번 출구 589m 거리',
    address: '서울 용산구 한강대로10길 11-44',
    date: '24.02.16~24.02.18',
    time: '11:00~18:00 (18일은 17:00)',
    host: 'Astronaut_for_V',
    mainSrc:
      'https://pbs.twimg.com/media/GFAIOqXbIAAz091?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GFAIOqYbgAAiADj?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5244679,
    longitude: 126.9647047,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 77,
    link: '/info/77',
    name: '카페 플랫',
    position: '서강대역 2번 출구 319m 거리',
    address: '서울 마포구 서강로9길 24 계린빌딩 지하1층',
    date: '24.02.16~24.02.18',
    time: '12:00~20:00 (18일은 18:00)',
    host: 'Blossomseason_',
    mainSrc:
      'https://pbs.twimg.com/media/GFTWfWVbwAAhW53?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GFTWjEba4AARxIE?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.553515,
    longitude: 126.9322492,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 78,
    link: '/info/78',
    name: '고수포차 본점',
    position: '합정역 6번 출구 397m 거리',
    address: '서울 마포구 양화로6길 67 2층',
    date: '24.02.16~24.02.18',
    time: '17:00~02:00\n16:00~02:00\n16:00~01:00',
    host: 'icebearboi',
    mainSrc:
      'https://pbs.twimg.com/media/GE0zPe4bQAAyey1?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GE0zQarbwAAjooY?format=jpg&name=4096x4096',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5487091,
    longitude: 126.9179905,
    bday_name: 'VERNON',
    bday_date: '1998.02.18',
  },
  {
    id: 79,
    link: '/info/79',
    name: '해피벌스데이',
    position: '홍대입구역 3번 출구 360m 거리',
    address: '서울 마포구 동교로34길 8 2층',
    date: '24.02.18~24.02.19',
    time: '12:00~19:00 (18일은 17:00)',
    host: 'hosha0526',
    mainSrc:
      'https://pbs.twimg.com/media/GGCErYea8AAXW4q?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GGCErZJbgAAudua?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 37.5609182,
    longitude: 126.9247985,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 80,
    link: '/info/80',
    name: '부산 모멘트',
    position: '서면역 2번 출구 409m 거리',
    address: '부산 부산진구 신천대로50번길 66 4,5층',
    date: '24.02.17~24.02.19',
    time: '12:00~19:00 (18일은 17:00)',
    host: 'hosha0526',
    mainSrc:
      'https://pbs.twimg.com/media/GGCErYea8AAXW4q?format=jpg&name=large',
    subSrc1:
      'https://pbs.twimg.com/media/GGCErZJbgAAudua?format=jpg&name=large',
    subSrc2: '',
    subSrc3: '',
    latitude: 35.1536592,
    longitude: 129.0600483,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
  {
    id: 81,
    link: '/info/81',
    name: '카페꼼마&얀쿠브레',
    position: '홍대입구역 3번 출구 101m 거리',
    address: '서울 마포구 양화로21길 23',
    date: '24.02.16~24.02.18',
    time: '10:00~22:00',
    host: 'WhiteTeeth218DK',
    mainSrc:
      'https://pbs.twimg.com/media/GGTCRCoa0AAOpS7?format=jpg&name=4096x4096',
    subSrc1:
      'https://pbs.twimg.com/media/GGTCRCoaYAA9XHu?format=jpg&name=4096x4096',
    subSrc2:
      'https://pbs.twimg.com/media/GGTCRCobUAA4v7M?format=jpg&name=4096x4096',
    subSrc3: '',
    latitude: 37.5592776,
    longitude: 126.9246827,
    bday_name: 'DK',
    bday_date: '1997.02.18',
  },
];

const parseStartDate = dateString => {
  const startDateString = dateString.split('~')[0].trim();
  return startDateString.split('.').map(Number);
};

export const sortedCafes = cafes.slice().sort((a, b) => {
  const startDateA = parseStartDate(a.date);
  const startDateB = parseStartDate(b.date);

  if (startDateA[2] !== startDateB[2]) {
    return startDateB[2] - startDateA[2];
  }

  if (startDateA[1] !== startDateB[1]) {
    return startDateB[1] - startDateA[1];
  }

  return startDateB[0] - startDateA[0];
});

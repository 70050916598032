import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from '../pages/Main/Main';
import About from '../pages/About/About';
import Total from '../pages/Total/Total';
import Contact from '../pages/Contact/Contact';
import Info from '../pages/Info/Info';
import Cafe from '../pages/Cafe/Cafe';
import ScrollTop from './ScrollTop/ScrollTop';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/total" element={<Total />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/info/:id" element={<Info />} />
        <Route path="/cafe/:id" element={<Cafe />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { cafes } from '../../libs/cafes';
import Header from '../../components/Header/Header';
import CafeCard from '../../components/CafeCard/CafeCard';

const parseStartDate = dateString => {
  const startDateString = dateString.split('~')[0].trim();
  return startDateString.split('.').map(Number);
};

const Cafe = () => {
  const { id } = useParams();
  const filteredCafes = cafes.filter(cafe => cafe.name === cafes[id].name);

  const sortedCafes = filteredCafes.slice().sort((a, b) => {
    const startDateA = parseStartDate(a.date);
    const startDateB = parseStartDate(b.date);

    if (startDateA[2] !== startDateB[2]) {
      return startDateA[2] - startDateB[2];
    }

    if (startDateA[1] !== startDateB[1]) {
      return startDateA[1] - startDateB[1];
    }

    return startDateA[0] - startDateB[0];
  });

  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="현재 카페 이벤트 리스트" />
      <div className="w-full flex flex-col gap-6 p-8 items-center justify-center">
        {sortedCafes.map(cafe => (
          <Link to={`/info/${cafe.id}`} key={cafe.date}>
            <CafeCard
              key={cafe.id}
              name={cafe.name}
              position={cafe.position}
              date={cafe.date}
              time={cafe.time}
              mainSrc={cafe.mainSrc}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Cafe;
